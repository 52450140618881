import React from 'react';
import './Aboutblock.scss'
import slide_1 from '../../image/s1.jpg'
import slide_2 from '../../image/s2.jpg'
import slide_3 from '../../image/s3.jpg'
import slide_4 from '../../image/s4.jpg'
import slide_5 from '../../image/s5.jpg'
import rail from '../../image/rail.png'
import ramp from '../../image/ramp.png'
import forklift from '../../image/forklift.png'
import clean from '../../image/clean (1).png'
import storage1 from '../../image/storage-stacks (1).png'
import storage2 from '../../image/storage-stacks.png'

import {Navigation, Pagination, Autoplay} from 'swiper';

import {Swiper, SwiperSlide} from 'swiper/react';


function AboutBlock(props) {
    return (
        <>
           <div className="wrapper_about">
               <div className="wrapper_inner_container">
                   <div className="wrapper_about__grid ">
                       <div className="wrapper_about_title">
                           <p className='about_title'>О НАШЕЙ КОМПАНИИ</p>
                           <p className='about_sub_title'>В своей работе мы учитываем Вашу потребность не только в
                               безопасной доставке грузов, но и их хранении.
                               Бывают ситуации, в которых Вам нужно не просто переместить груз из точки А в точку Б, а
                               еще и придержать его какое-то время. В этом случае сервис по хранению оказывается
                               незаменим.
                           </p>
                       </div>

                       <div className="about_image">
                           <div style={{position: 'relative'}}>
                               <Swiper
                                   modules={[Pagination, Autoplay, Navigation]}
                                   spaceBetween={0}
                                   speed={1200}
                                   autoplay={
                                       {delay: 3500,}
                                   }
                                   breakpoints={{
                                       0: {
                                           slidesPerView: 1,
                                       }
                                   }}
                                   navigation={{
                                       prevEl: `.prev-hero-block-slide`,
                                       nextEl: `.next-hero-block-slide`,
                                   }}
                                   navigation={true}
                                   className={'swiper_about'}
                               >
                                   <SwiperSlide>
                                       <img className={'about_image_slide'} src={slide_1} alt=""/>
                                   </SwiperSlide>
                                   <SwiperSlide>
                                       <img className={'about_image_slide'} src={slide_2} alt=""/>
                                   </SwiperSlide>
                                   <SwiperSlide>
                                       <img className={'about_image_slide'} src={slide_3} alt=""/>
                                   </SwiperSlide>
                                   <SwiperSlide>
                                       <img className={'about_image_slide'} src={slide_4} alt=""/>
                                   </SwiperSlide>
                                   <SwiperSlide>
                                       <img className={'about_image_slide'} src={slide_5} alt=""/>
                                   </SwiperSlide>
                               </Swiper>
                           </div>
                       </div>

                   </div>
                   <div className="about_advantages">
                       <div className="about_advantages__item">
                           <img src={storage1} alt=""/>
                           <p className='about_advantages_title'>Теплый склад (постоянно плюсовая температура склада от
                               +10 до +16 в зимнее время года)</p>
                       </div>
                       <div className="about_advantages__item">
                           <img src={storage2} alt=""/>
                           <p className='about_advantages_title'>Три зоны погрузки/разгрузки (трое ворот)</p>
                       </div>
                       <div className="about_advantages__item">
                           <img src={forklift} alt=""/>
                           <p className='about_advantages_title'>Вся необходимая спецтехника для работы на складе</p>
                       </div>
                       <div className="about_advantages__item">
                           <img src={rail} alt=""/>
                           <p className='about_advantages_title'>Ж/Д тупик</p>
                       </div>
                       <div className="about_advantages__item">
                           <img src={ramp} alt=""/>
                           <p className='about_advantages_title'>Мобильный пандус (передвигается по территории, с
                               регулировкой высоты)</p>
                       </div>
                       <div className="about_advantages__item">
                           <img src={clean} alt=""/>
                           <p className='about_advantages_title'>Безпылевой пол</p>
                       </div>
                   </div>
               </div>
           </div>
        </>
    );
}

export default AboutBlock;