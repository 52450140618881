import React from 'react';
import './Footer.scss'
import logo from '../../image/logo.svg'
import synergy from '../../image/synergy.png'
import Media from "react-media";
import {phoneData, phoneDataPriority} from "../../const";

function Footer(props) {
    return (
        <>
            <div className="wrapper_footer">
                <Media query="(min-width: 769px)" render={() =>
                    (<div className="wrapper_footer_info wrapper_inner_container">
                            <div className="left_info_block">
                                <div>
                                    <img className='footer_img_logo' src={logo} alt=""/>
                                    <p className='footer_color_txt'>Kabotazh@inbox.ru</p>
                                    <a href={`tel:${phoneDataPriority.value}`}><p className='footer_color_txt'>{phoneDataPriority.title}</p></a>
                                    <a href={`tel:${phoneData.value}`}><p className='footer_color_txt'>{phoneData.title}</p></a>
                                </div>
                                <p className='copyright footer_color_txt'>2020 © ООО «Каботаж». Все права защищены. <a
                                    onClick={props.toggleIsPoliticy}
                                    className='politics'>Политика конфиденциальности.</a></p>
                            </div>
                            <div className="right_info_block">
                                <div>
                                    <p className='footer_color_txt'>ОГРН 1205400017570</p>
                                    <p className='footer_color_txt'>ИНН 54022060094</p>
                                    <p className='footer_color_txt'>КПП 540201001</p>
                                </div>
                                <a className='synergy' href="https://synergy-web.ru/"><img src={synergy} alt="synergy"/></a>
                            </div>
                        </div>
                    )}/>
                <Media query="(max-width: 768px)" render={() =>
                    (<div className="wrapper_footer_info wrapper_inner_container">
                            <div className="left_info_block">
                                <img className='footer_img_logo' src={logo} alt=""/>
                                <div className="wrapper_footer_info_mob">
                                    <p className='footer_color_txt'>Kabotazh@inbox.ru</p>
                                    <div className={'footer-mobile-wrapper-phones'}>
                                        <a href={`tel:${phoneDataPriority.value}`}><p
                                            className='footer_color_txt'>{phoneDataPriority.title}</p></a>
                                        <a href={`tel:${phoneData.value}`}><p
                                            className='footer_color_txt'>{phoneData.title}</p></a>
                                    </div>
                                </div>
                                <p className='mop_padding_footer footer_color_txt'>ОГРН 1205400017570</p>
                                <p className='mop_padding_footer footer_color_txt'>ИНН 54022060094</p>
                                <p className='mop_padding_footer footer_color_txt'>КПП 540201001</p>
                                <p className='copyright footer_color_txt'>2020 © ООО «Каботаж». Все права защищены. <a
                                    onClick={props.toggleIsPoliticy}
                                    className='politics'>Политика конфиденциальности.</a></p>
                                <a className='synergy' href="https://synergy-web.ru/"><img src={synergy} alt="synergy"/></a>
                            </div>
                        </div>
                    )}/>
            </div>
        </>
    );
}

export default Footer;
