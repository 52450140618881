import * as React from "react";
import './Calculator.scss'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import cross from '../../image/cross.png'
import Media from "react-media";

class Calculator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            style_add_service:{},
            add_service_isShow:false,
            style_curgo_service:{},
            curgo_service_isShow:false,


            /*____CALCULATOR STATE____*/
            meter: 1,
            monthValue: 1,
            priceOfMeter: 450,
            price: '450',
            packaging: false,
            fix_cargo: false,
            formPallete: false,
            palleteStrech: false,
            workCurgo: false,
            workCurgoMechanized: false,
            formCarToCar: false,
            /*___CALCULATOR STATE___*/

        };

        this.handleMeterChange = this.handleMeterChange.bind(this);
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.onClickAddServiceCross = this.onClickAddServiceCross.bind(this)
        this.onClickCurgoServiceCross = this.onClickCurgoServiceCross.bind(this)
    }
    onClickCurgoServiceCross(){
        if(this.state.curgo_service_isShow){
            this.setState({
                style_curgo_service: {

                    transition: '0.5s'
                },
                curgo_service_isShow:!this.state.curgo_service_isShow
            })

        }
        else{
            this.setState({
                style_curgo_service:{
                    transform: 'rotate(-45deg)',
                    transition: '0.5s'
                },
                curgo_service_isShow:!this.state.curgo_service_isShow
            })
        }

    }

    onClickAddServiceCross(){
        if(this.state.add_service_isShow){
            this.setState({
                style_add_service: {

                    transition: '0.5s'
                },
                add_service_isShow:!this.state.add_service_isShow
            })

        }
        else{
            this.setState({
                style_add_service:{
                    transform: 'rotate(-45deg)',
                    transition: '0.5s'
                },
                add_service_isShow:!this.state.add_service_isShow
            })
        }

    }
    
    addCurgoService(){
        let priceCurgoService =  0

        if (this.state.workCurgo) {
            this.setState({
                workCurgoMechanized: false,
                formCarToCar: false,

            })
            priceCurgoService = priceCurgoService + 200
        }
        if (this.state.workCurgoMechanized) {
            this.setState({
                workCurgo: false,
                formCarToCar: false,

            })
            priceCurgoService = priceCurgoService + 130
        }
        if (this.state.formCarToCar) {
            this.setState({
                workCurgo: false,
                workCurgoMechanized: false,

            })
            priceCurgoService = priceCurgoService + 200
        }
        this.state.packaging ? priceCurgoService = priceCurgoService + 1000 : priceCurgoService = priceCurgoService
        this.state.formPallete ? priceCurgoService = priceCurgoService + 100 : priceCurgoService = priceCurgoService
        this.state.palleteStrech ? priceCurgoService = priceCurgoService + 100 : priceCurgoService = priceCurgoService
        return priceCurgoService
    }




    handleMeterChange = (value) => {

        this.setState({meter: value});

        let priceCurgoService = this.addCurgoService()
        this.setState({price:  ( this.state.priceOfMeter * this.state.meter) * this.state.monthValue + (this.state.meter * priceCurgoService)})
    };
    handleMonthChange = (value) => {

        this.setState({monthValue: value});

        let priceCurgoService = this.addCurgoService()
        this.setState({price :( this.state.priceOfMeter * this.state.meter) * this.state.monthValue + (this.state.meter * priceCurgoService) } )
    };


    render() {

        let {meter, monthValue, price , style_add_service , add_service_isShow,style_curgo_service, curgo_service_isShow} = this.state;

        return (

            <>
                <div className="wrapper_calculator">
                    <div className="wrapper_range">
                        <div className="period">
                            <div className="wrapper_info_range">
                                <p className='range_value'>{monthValue} мес</p>
                                <p className='range_atribute'>Срок хранения</p>

                            </div>
                            <Slider value={monthValue} onChangeComplete={this.handleMonthChange}  onChange={this.handleMonthChange} min={1} max={12}
                                        className='range_calculator' step={1}
                            />


                        </div>
                        <div className="size">
                            <div className="wrapper_info_range">
                                <p className='range_value'>{meter} м<sup>2</sup></p>
                                <p className='range_atribute'>Размер участка</p>
                            </div>
                            <Slider onChangeComplete={this.handleMeterChange} onChange={this.handleMeterChange} value={meter} min={1} max={100}
                                        step={1} className='range_calculator'/>
                        </div>
                    </div>
                    <Media query="(max-width: 1024px)" render={() =>
                        ( <>
                            <div className="wrapper_add_service">
                                <p className='range_atribute'>Дополнительные услуги <div onClick={this.onClickAddServiceCross} style={style_add_service} className="container_add_service"><img  className='cross' src={cross} alt=""/></div></p>
                                { add_service_isShow ? <form>
                                    <input onChange={(event) => {
                                        this.setState({formPallete: this.state.formPallete = event.target.checked}, this.handleMeterChange(meter))
                                    }} type="checkbox" className="custom-checkbox i" id="formPallete" name="formPallete"
                                           checked={this.state.formPallete}/>
                                    <label className='input_text' htmlFor="formPallete">Формирование палеты <span
                                        className='input_text__blue'>&nbsp;<sup>+100₽ за м<sup>2</sup></sup></span></label>
                                    <input onChange={(event) => {
                                        this.setState({palleteStrech: this.state.palleteStrech = event.target.checked}, this.handleMeterChange(meter))
                                    }} type="checkbox" className="custom-checkbox i" id="palleteStrech" name="palleteStrech"
                                           checked={this.state.palleteStrech}/>
                                    <label className='input_text' htmlFor="palleteStrech">Паллетирование паллеты
                                        стретч-пленкой <span
                                            className='input_text__blue'>&nbsp;<sup>+100₽ за м<sup>2</sup></sup></span> </label>
                                </form> :null}
                            </div>
                        <div className="wrapper_loading_cargo">
                        <p className='range_atribute'>Выгрузка/погрузка <div  onClick={this.onClickCurgoServiceCross} style={style_curgo_service} className="container_add_service"><img  className='cross' src={cross} alt=""/></div></p>
                        {  curgo_service_isShow ? <form>
                            <input onChange={(event) => {
                                this.setState({workCurgo: this.state.workCurgo = event.target.checked}, this.handleMeterChange(meter))
                            }} type="checkbox" className="custom-checkbox_radio i" id="workCurgo" name="workCurgo"
                                   checked={this.state.workCurgo}/>
                            <label className='input_text' htmlFor="workCurgo">Ручные работы <span
                                className='input_text__blue'> &nbsp;<sup>+200₽ за м<sup>2</sup></sup></span> </label>
                            <input onChange={(event) => {
                                this.setState({workCurgoMechanized: this.state.workCurgoMechanized = event.target.checked}, this.handleMeterChange(meter))
                            }} type="checkbox" className="custom-checkbox_radio i" id="workCurgoMechanized"
                                   name="workCurgoMechanized" checked={this.state.workCurgoMechanized}/>
                            <label className='input_text' htmlFor="workCurgoMechanized">Механизированные
                                работы <span
                                    className='input_text__blue'> &nbsp;<sup>+130₽ за м<sup>2</sup></sup></span> </label>
                            <input onChange={(event) => {
                                this.setState({formCarToCar: this.state.formCarToCar = event.target.checked}, this.handleMeterChange(meter))
                            }} type="checkbox" className="custom-checkbox_radio i" id="formCarToCar" name="formCarToCar"
                                   checked={this.state.formCarToCar}/>
                            <label className='input_text' htmlFor="formCarToCar">Перегрузка из машины в машину <span
                                className='input_text__blue'> &nbsp;<sup>+200 за м<sup>2</sup></sup></span></label>
                        </form> :null}
                        </div>
</>
                        )}
                    />
                    <Media query="(min-width: 1025px)" render={() =>
                        ( <>
                                <div className="wrapper_add_service">
                                    <p className='range_atribute'>Дополнительные услуги </p>
                                  <form>
                                        <input onChange={(event) => {
                                            this.setState({formPallete: this.state.formPallete = event.target.checked}, this.handleMeterChange(meter))
                                        }} type="checkbox" className="custom-checkbox i" id="formPallete" name="formPallete"
                                               checked={this.state.formPallete}/>
                                        <label className='input_text' htmlFor="formPallete">Формирование палеты <span
                                            className='input_text__blue'>&nbsp;<sup>+100₽ за м<sup>2</sup></sup></span></label>
                                        <input onChange={(event) => {
                                            this.setState({palleteStrech: this.state.palleteStrech = event.target.checked}, this.handleMeterChange(meter))
                                        }} type="checkbox" className="custom-checkbox i" id="palleteStrech" name="palleteStrech"
                                               checked={this.state.palleteStrech}/>
                                        <label className='input_text' htmlFor="palleteStrech">Паллетирование паллеты
                                            стретч-пленкой <span
                                                className='input_text__blue'>&nbsp;<sup>+100₽ за м<sup>2</sup></sup></span> </label>
                                    </form>
                                </div>
                                <div className="wrapper_loading_cargo">
                                    <p className='range_atribute'>Выгрузка/погрузка </p>
                                  <form>
                                        <input onChange={(event) => {
                                            this.setState({workCurgo: this.state.workCurgo = event.target.checked}, this.handleMeterChange(meter))
                                        }} type="checkbox" className="custom-checkbox_radio i" id="workCurgo" name="workCurgo"
                                               checked={this.state.workCurgo}/>
                                        <label className='input_text' htmlFor="workCurgo">Ручные работы <span
                                            className='input_text__blue'> &nbsp;<sup>+200₽ за м<sup>2</sup></sup></span> </label>
                                        <input onChange={(event) => {
                                            this.setState({workCurgoMechanized: this.state.workCurgoMechanized = event.target.checked}, this.handleMeterChange(meter))
                                        }} type="checkbox" className="custom-checkbox_radio i" id="workCurgoMechanized"
                                               name="workCurgoMechanized" checked={this.state.workCurgoMechanized}/>
                                        <label className='input_text' htmlFor="workCurgoMechanized">Механизированные
                                            работы <span
                                                className='input_text__blue'> &nbsp;<sup>+130₽ за м<sup>2</sup></sup></span> </label>
                                        <input onChange={(event) => {
                                            this.setState({formCarToCar: this.state.formCarToCar = event.target.checked}, this.handleMeterChange(meter))
                                        }} type="checkbox" className="custom-checkbox_radio i" id="formCarToCar" name="formCarToCar"
                                               checked={this.state.formCarToCar}/>
                                        <label className='input_text' htmlFor="formCarToCar">Перегрузка из машины в машину <span
                                            className='input_text__blue'> &nbsp;<sup>+200 за м<sup>2</sup></sup></span></label>
                                    </form>
                                </div>
                            </>
                        )}
                    />

                    <div className="wrapper_calculator_price">
                        <p className='fihish_price'>{price} ₽</p>
                        <p className='fihish_price_text'>Примерная&nbsp;стоимость </p>
                    </div>
                    <div className="wrapper_btn_calculator">
                        <button onClick={this.props.toggleIsForm} className='btn_blue'><p>Оставить&nbsp;заявку</p>
                        </button>

                    </div>
                </div>

            </>
        )

    }
}


export default Calculator