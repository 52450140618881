import React from 'react';
import './CalculatorBlock.scss'
import banner from '../../image/bannerCalculator.png'
import Calculator from "../Calculator/Сalculator";

function CalculatorBlock(props) {
    return (
        <>
            <div className="wrapper_calculator_block">
                <img className='banner_calculator' src={banner} alt=""/>
                <div className="wrapper_banner_text">
                    <p className="calculator_banner_title">Рассчитайте стоимость</p>
                    <p className="calculator_banner_sub_title">хранения на нашем складе</p>
                </div>
                <div className="wrapper_calculator__absolute wrapper_inner_container ">
                    <Calculator  toggleIsForm={props.toggleIsForm}/>
                </div>

            </div>
        </>
    );
}

export default CalculatorBlock;