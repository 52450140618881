import React from 'react';
import './MoreDetailBlock.scss'
import banner from '../../image/moreDetailBanner.png'
import car from '../../image/more_car.png'

function MoreDetailBlock() {
    return (
        <>
            <div className="wrapper_more_detail_block">
                <img  className='more_banner_back' src={banner} alt=""/>
                <div className="wrapper_more_detail_text wrapper_inner_container">
                    <div className='more_banner_content_text'>
                        <p className='more_detail_title'>НУЖНЫ ГРУЗОПЕРЕВОЗКИ?</p>
                        <p className='more_detail_sub_title'>Мы занимаеся логистическими решениями любой сложности</p>
                        <a href='http://tt-nsk.ru/' ><button className='btn_more_detail btn_org'><p>Подробнее</p></button></a>
                    </div>
                    <div className="wrapper_more_detail_img">
                        <img className='more_banner_car' src={car} alt=""/>
                    </div>

                </div>
            </div>
        </>
    );
}

export default MoreDetailBlock;