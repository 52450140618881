import React from 'react';
import './MainSlider.scss';
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper';

import {Swiper, SwiperSlide} from 'swiper/react';
import background_img from '../../image/slideMain.jpg'
import background_img02 from '../../image/main_banner02.jpg'
import background_img04 from '../../image/main_banner04.png'

import car from '../../image/car.png'
import crosscoding from '../../image/crosscoding.png'
import crosscoding_mob from '../../image/crosscoding_mob.png'
import sklad from '../../image/sklad.png'
import car02 from '../../image/main_bus.png'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import {useMediaQuery} from "../../utils/useMediaQuery";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

function MainSlider() {

    const mob = useMediaQuery('(max-width:376px)')

    return (
        <>

            <div className="wrapper_main_swiper">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    paginationClickable={true}
                    watchSlidesProgress={true}
                    pagination={{clickable: true}}
                    loop={true}
                    parallax={true}
                    autoplay={
                        {delay: 5000}
                    }
                    speed={600}
                >
                    <SwiperSlide>
                        <div style={{backgroundImage: "url(" + (mob ? crosscoding_mob : crosscoding) + ")"}} className="wrapper_slide_block">
                            <div className="wrapper_inner_container main_slider_wrapper_text">
                                <div className="main_slider_text">
                                    <h1 className='main_slider_title'>кросс-докинг </h1>
                                    <p className='main_slider_sub_title'>Оперативность при сборке
                                        заказов, <br/> качественная упаковка </p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div style={{backgroundImage: "url(" + background_img + ")"}} className="wrapper_slide_block">
                            <img src={car} className='car' alt=""/>
                            <div className="wrapper_inner_container main_slider_wrapper_text">
                                <div className="main_slider_text">
                                    <h1 className='main_slider_title'>Ответственное <br/> хранение </h1>
                                    <p className='main_slider_sub_title'>Комплектация заказов, <br/> отгрузка по
                                        клиентам </p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div style={{backgroundImage: "url(" + background_img02 + ")"}} className="wrapper_slide_block">
                            <img src={car02} className='car02' alt=""/>
                            <div className="wrapper_inner_container main_slider_wrapper_text">
                                <div className="main_slider_text">
                                    <h1 className='main_slider_title'>ЛОГИСТИЧЕСКИЕ <br/> РЕШЕНИЯ </h1>
                                    <p className='main_slider_sub_title'>Узнайте о специальной скидке
                                        <br/> при заказе с сайта. </p>
                                    <a href='http://tt-nsk.ru/'>
                                        <button className='btn_more_detail btn_org'><p>Подробнее</p></button>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div style={{backgroundImage: "url(" + background_img04 + ")"}} className="wrapper_slide_block">
                            <img src={sklad} className='car04' alt=""/>
                            <div className="wrapper_inner_container main_slider_wrapper_text">
                                <div className="main_slider_text">
                                    <h1 className='main_slider_title'>9 рублей/метр<sup>3</sup> <br/> До конца месяца</h1>
                                    <p className='main_slider_sub_title'>Условия уточняйте у менеджеров. </p>
                                </div>

                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>


            </div>

        </>
    );
}

export default MainSlider;
