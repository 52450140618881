import React, {useEffect, useState} from 'react';
import './App.scss';
import MainSlider from "./Components/MainSlider/MainSlider";
import Header from "./Components/Header/Header";
import AdvantagesBlock from "./Components/AdvanagesBlock/AdvantagesBlock";
import CalculatorBlock from "./Components/CalculatorBlock/CalculatorBlock";
import OurWorkBlock from "./Components/OurWorkBlock/OurWorkBlock";
import MoreDetailBlock from "./Components/MoreDetailBlock/MoreDetailBlock";
import AboutBlock from "./Components/AboutBlock/AboutBlock";
import Contact from "./Components/Contanct/Contact";
import Footer from "./Components/Footer/Footer";
import FormCall from "./Components/FormCall/FormCall";
import FormCallSucces from "./Components/FormCall/FormCallSuccess";
import Politicy from "./Components/FormCall/Politicy";
import ReactGa from 'react-ga'

export const ShowMenuContext = React.createContext()
export const ShowMenuSuccessContext = React.createContext()
export const PoliticyContext = React.createContext()
function App() {

    useEffect(()=>{
        ReactGa.initialize('UA-186115672-1')
        ReactGa.pageview('/')
    }, [])

    const [isShowFormSucces, setIsShowFormSuccess] = useState(false)
    const [isShowPoliticy, setIsShowPoliticy] = useState(false)
    const [isShowForm, setIsShowForm] = useState(false)
    const toggleIsForm = () => setIsShowForm(prevState => !prevState)
    const toggleIsFormSuccess = () => setIsShowFormSuccess(prevState => !prevState)
    const toggleIsPoliticy = () => setIsShowPoliticy(prevState => !prevState)
    return (

        <>
            <PoliticyContext.Provider value={isShowPoliticy} >
              <ShowMenuSuccessContext.Provider value={isShowFormSucces} >
                <ShowMenuContext.Provider value={isShowForm} >
                    <div className="wrapper">
                        <Politicy toggleIsPoliticy={toggleIsPoliticy} />
                        <FormCallSucces toggleIsForm={toggleIsFormSuccess}/>
                        <FormCall  toggleIsForm={toggleIsForm}   toggleIsFormSuccess={toggleIsFormSuccess}/>
                        <MainSlider/>
                        <Header  toggleIsForm={toggleIsForm}/>
                        <AdvantagesBlock/>
                        <CalculatorBlock  toggleIsForm={toggleIsForm}/>
                        <OurWorkBlock/>
                        <MoreDetailBlock/>
                        <AboutBlock/>
                        <Contact toggleIsForm={toggleIsForm}/>
                        <Footer toggleIsPoliticy={toggleIsPoliticy}/>
                    </div>
                </ShowMenuContext.Provider>
              </ShowMenuSuccessContext.Provider>
            </PoliticyContext.Provider>
        </>
    );
}

export default App;
