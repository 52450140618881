import React, {useContext, useState} from 'react';
import './FormCall.scss'
import close from '../../image/close.svg'
import {ShowMenuContext} from "../../App";
import email from 'emailjs-com';
import InputElement from "react-input-mask";
import load from '../../image/load.gif'
import ReactGA from "react-ga";

function FormCall(props) {

    const [isShowLoad, setIsShowLoad] = useState(false)
    const toggleIsLoad = () => setIsShowLoad(prevState => !prevState)
    const clickHandler = () => {
        ReactGA.event(
            {
                category: 'Form',
                action: 'Click send form'
            }
        )
    }


    function sendEmail(e) {
        e.preventDefault();
        toggleIsLoad()
        email.sendForm('gmail', 'template_ckphyrk', e.target, 'user_UetrEwfIevr4zGN8VgONM')
            .then((result) => {


                props.toggleIsForm()
                toggleIsLoad()
                props.toggleIsFormSuccess()

            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }

    const isShowForm = useContext(ShowMenuContext)
    const [message, setMessage] = useState('');

    if (!isShowForm) {
        return null
    }


    return (
        <div className="wrapper_form_call">
            <div className="form_call">

                <img onClick={props.toggleIsForm} className='form_call_close' src={close} alt=""/>
                <p className='form_call_title'>ЗАКАЗАТЬ ЗВОНОК </p>
                <p className='form_call_sub_title'>Заполните форму и мы свяжемся с вами в ближайшее время</p>
                <form className="contact-form" onSubmit={sendEmail}>
                    <div>
                        <span className='text_form_call'>Имя</span><br/>
                        <input
                            name='name'
                            required className='form_call_input' type="input"/><br/>
                        <span className='text_form_call'>Телефон</span><br/>
                        <InputElement placeholder='+7('
                                      name='phone' mask="+7 (999) 999 99 99"
                                      required className='form_call_input' type="input"/>
                        <button onClick={clickHandler} className='form_call_btn'><p>Оставить заявку</p></button>
                        {isShowLoad ? <img className='load' src={load} alt=""/> : null}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FormCall;