import React, {useContext, useState} from 'react';
import './FormCall.scss'
import close from '../../image/close.svg'
import {ShowMenuSuccessContext} from "../../App";


function FormCallSucces(props) {


    const isShowFormSucces = useContext(ShowMenuSuccessContext)


    if (!isShowFormSucces) {
        return null
    }


    return (
        <div className="wrapper_form_call">
            <div className="form_call">

                <img onClick={props.toggleIsForm} className='form_call_close' src={close} alt=""/>
                <p className='form_call_title'>Заявка успешно отправлена </p>
                <p className='form_call_sub_title'> Мы свяжемся с вами в ближайшее время</p>

            </div>
        </div>
    );
}

export default FormCallSucces;