import React from 'react';
import './TitleBlock.scss'

function TitleBlock(props) {
    return (
        <>
            <div className="wrapper_title_block ">
                <p className="title_block_title">{props.title}</p>
                <p className="title_block_sub_title">{props.sub_title}</p>
            </div>
        </>
    );
}

export default TitleBlock;