import React from 'react';
import './OurWorkBlock.scss'
import TitleBlock from "../TitleBlock/TitleBlock";
import boxs from '../../image/boxs.svg'
import bus from '../../image/bus.svg'
import car from '../../image/car_icon.svg'
import angar from '../../image/angar.svg'

function OurWorkBlock() {
    return (
        <>
            <div className="wrapper_our_work_block">
                <div className="wrapper_inner_container">
                    <TitleBlock title='КАК МЫ РАБОТАЕМ?' sub_title='Процесс нашей работы по шагам'/>
                    <div className="wrapper_our_work_items">
                        <div className=" item_up__top our_work_item number_color_1">
                            <p className='our_work_item_number'>01</p>
                            <div className="wrapper_our_work_icon">
                                <img className='size_box boxs__left' src={boxs} alt=""/>
                            </div>
                            <div><p className='our_work_item_title__blue'>Оставляете заявку</p>
                                <p className='our_work_item_sub_title__blue'>На нашем сайте</p></div>

                        </div>
                        <div className="our_work_item number_color_2">
                            <p className='our_work_item_number'>02</p>
                            <div className="wrapper_our_work_icon">
                                <img className='size_bus' src={bus} alt=""/>
                            </div>
                            <div>
                                <p className='our_work_item_title__blue'>Уточняем детали</p>
                                <p className='our_work_item_sub_title__blue'>На нашем сайте</p>
                            </div>

                        </div>
                        <div className="item_up__top our_work_item number_color_3">
                            <p className='our_work_item_number'>03</p>
                            <div className="wrapper_our_work_icon">
                                <img className='size_car' src={car} alt=""/>
                            </div>
                            <div>
                                <p className='colot_text__white our_work_item_title__white'>Заключаем договор</p>
                                <p className=' colot_text__white our_work_item_sub_title__white'>На нашем сайте</p>
                            </div>

                        </div>
                        <div className="our_work_item number_color_4">
                            <p className='our_work_item_number'>04</p>
                            <div className="wrapper_our_work_icon">
                                <img className='size_angar' src={angar} alt=""/>
                            </div>
                            <div>
                                <p className=' colot_text__white our_work_item_title__white'>Счёт по факту</p>
                                <p className=' colot_text__white our_work_item_sub_title__white'>На нашем сайте</p>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default OurWorkBlock;