import React from 'react';
import './Contact.scss'
import YandexMap from "./YandexMap";
import banner from '../../image/contactBanner.png'
import {phoneData, phoneDataPriority} from "../../const";

function Contact(props) {
    return (
        <>
            <div className="wrapper_contact">
                <div className="contact_info">
                    <img className='contact_banner' src={banner} alt=""/>
                    <div className="wrapper_contact_info wrapper_padding__left">
                        <p className="contact_title">КОНТАКТЫ</p>
                        <p className="contact_adress contact__item">Новосибирск, ул. 2-я Станционная, 30к18</p>
                        <p className="contact_main contact__item">Kabotazh@inbox.ru</p>
                        <a href={`tel:${phoneDataPriority.value}`} className="contact_phone contact__item">{phoneDataPriority.title}</a>
                        <a href={`tel:${phoneData.value}`} className="contact_phone contact__item">{phoneData.title}</a>
                        <button onClick={props.toggleIsForm} className=' contact_btn btn_org'><p>Оставить заявку</p></button>
                    </div>
                </div>
                <div className="maps">
<YandexMap/>
                </div>
            </div>

        </>
    );
}

export default Contact;
