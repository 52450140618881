import React from 'react';
import './Header.scss'
import phone from '../../image/phone.svg'
import Media from "react-media";
import ReactGA from "react-ga";
import {phoneData, phoneDataPriority} from "../../const";

class Header extends React.Component {

    state = {
        color: '',
        color_text: 'white'
    }

    listenScrollEvent = (e) => {
        if (window.scrollY > 100) {
            this.setState({color: 'white'})
            this.setState({color_text: '#044582'})
        } else {
            this.setState({color: ''})
            this.setState({color_text: 'white'})
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
    }

    clickHandler = () => {
        ReactGA.event(
            {
                category: 'Button',
                action: 'Click phone'
            }
        )
    }

    render() {

        return (
            <>
                <div style={{background: this.state.color}} className="wrapper_header">
                    <nav className=' nav_header wrapper_inner_container'>
                        <ul className='header_ul'>
                            <li>
                                <svg className='svg_logo' width="167" height="48" viewBox="0 0 167 48" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.7798 25.9441H1H0.782885V26.1612V41.5055C0.782885 44.7613 3.43118 47.4096 6.68655 47.4096C9.29864 47.4096 11.5177 45.7045 12.2941 43.3506H24.4384C25.2147 45.7045 27.4338 47.4096 30.0459 47.4096C33.3013 47.4096 35.9496 44.7613 35.9496 41.5055V36.1353H39.3882V43.1335V43.3506H39.6054H56.5815H56.7986V43.1335V39.878V39.6609H56.5815H43.0775V9.56629V9.34918H42.8604H39.6054H39.3882V9.56629V25.9441H35.9496V15.4271V15.3571L35.9086 15.3002L25.5151 0.87309L25.4501 0.782885H25.3389H11.997H11.7798V1V25.9441ZM15.4691 4.47215H23.5608L32.2599 16.5476V25.9441H15.4691V4.47215ZM6.68655 43.7199C5.466 43.7199 4.47215 42.7265 4.47215 41.5055C4.47215 40.2851 5.46598 39.2915 6.68655 39.2915C7.90752 39.2915 8.90095 40.285 8.90095 41.5055C8.90095 42.7265 7.9075 43.7199 6.68655 43.7199ZM30.0459 35.6019C27.4338 35.6019 25.2147 37.3065 24.4384 39.6609H12.2941C11.5177 37.3069 9.29864 35.6019 6.68655 35.6019C5.90412 35.6019 5.1565 35.7555 4.47215 36.0334V29.6337H32.2599V36.0336C31.5758 35.7554 30.8283 35.6019 30.0459 35.6019ZM30.0459 43.7199C28.825 43.7199 27.8315 42.7265 27.8315 41.5055C27.8315 40.2846 28.825 39.2911 30.0459 39.2911C31.2664 39.2911 32.2599 40.2846 32.2599 41.5055C32.2599 42.7265 31.2664 43.7199 30.0459 43.7199ZM35.9496 32.4461V29.6337H39.3882V32.4461H35.9496Z"
                                        fill={`${this.state.color_text}`} stroke={`${this.state.color_text}`}
                                        strokeWidth="0.43423"/>
                                    <path
                                        d="M59.5467 17.1902H64.5285L57.9671 25.154L65.1056 33.7865H59.972L53.198 25.3667V33.7865H48.6719V17.1902H53.198V25.2452L59.5467 17.1902Z"
                                        fill={`${this.state.color_text}`}/>
                                    <path
                                        d="M71.7016 16.8558C73.9293 16.8558 75.6304 17.3422 76.8049 18.3149C77.9997 19.2673 78.5971 20.7364 78.5971 22.7223V33.7865H75.438L74.557 31.5372H74.4355C73.7268 32.4288 72.9775 33.0772 72.1877 33.4825C71.3979 33.8878 70.3144 34.0904 68.9374 34.0904C67.459 34.0904 66.2338 33.6649 65.2618 32.8138C64.2897 31.9424 63.8037 30.6151 63.8037 28.8319C63.8037 27.0689 64.4214 25.772 65.6567 24.9412C66.892 24.0901 68.745 23.624 71.2156 23.543L74.1014 23.4518V22.7223C74.1014 21.8509 73.8685 21.2126 73.4027 20.8073C72.9572 20.4021 72.3294 20.1994 71.5194 20.1994C70.7093 20.1994 69.9195 20.321 69.15 20.5642C68.3805 20.7871 67.6109 21.0708 66.8414 21.4153L65.3529 18.3452C66.244 17.8792 67.2262 17.5144 68.2995 17.251C69.393 16.9876 70.5271 16.8558 71.7016 16.8558ZM72.3396 26.157C70.8815 26.1976 69.8689 26.461 69.3019 26.9473C68.7349 27.4337 68.4513 28.072 68.4513 28.8623C68.4513 29.5513 68.6539 30.0478 69.0589 30.3517C69.4639 30.6354 69.9904 30.7773 70.6385 30.7773C71.6105 30.7773 72.4307 30.4936 73.099 29.9262C73.7673 29.3385 74.1014 28.5178 74.1014 27.4641V26.0963L72.3396 26.157Z"
                                        fill={`${this.state.color_text}`}/>
                                    <path
                                        d="M79.6456 23.8469C79.6456 20.1791 80.3341 17.332 81.7112 15.3056C83.1085 13.2792 85.4071 11.9823 88.6067 11.4149C89.68 11.2326 90.7635 11.0806 91.857 10.959C92.9708 10.8171 94.115 10.6753 95.2896 10.5334L95.806 14.4849C95.1175 14.566 94.358 14.6572 93.5277 14.7585C92.7177 14.8598 91.9076 14.9611 91.0976 15.0625C90.3078 15.1435 89.599 15.2347 88.9712 15.336C87.9587 15.4981 87.1183 15.7514 86.45 16.0959C85.7817 16.4202 85.2653 16.947 84.9008 17.6765C84.5565 18.406 84.3439 19.4598 84.2629 20.8377H84.4755C84.7185 20.4527 85.0527 20.0677 85.4779 19.6827C85.9235 19.2977 86.4702 18.9734 87.1183 18.71C87.7663 18.4466 88.5055 18.3149 89.3358 18.3149C91.3204 18.3149 92.8898 18.9228 94.0441 20.1386C95.2187 21.3545 95.806 23.1681 95.806 25.5795C95.806 27.4641 95.4617 29.0345 94.7732 30.2909C94.0846 31.5473 93.1328 32.4997 91.9178 33.1482C90.7027 33.7763 89.2953 34.0904 87.6954 34.0904C85.245 34.0904 83.2908 33.2089 81.8327 31.446C80.3747 29.683 79.6456 27.15 79.6456 23.8469ZM87.9688 30.4125C88.9206 30.4125 89.6902 30.0883 90.2774 29.4398C90.885 28.7914 91.1887 27.6363 91.1887 25.9747C91.1887 24.6575 90.966 23.6139 90.5204 22.8439C90.0952 22.0536 89.3459 21.6584 88.2726 21.6584C87.6245 21.6584 87.017 21.8205 86.45 22.1448C85.9032 22.4487 85.4374 22.8033 85.0527 23.2086C84.6679 23.5936 84.4046 23.928 84.2629 24.2117C84.2629 25.2452 84.3742 26.2381 84.597 27.1905C84.8198 28.1429 85.1944 28.9231 85.7209 29.531C86.2677 30.1187 87.017 30.4125 87.9688 30.4125Z"
                                        fill={`${this.state.color_text}`}/>
                                    <path
                                        d="M112.029 25.4579C112.029 28.2139 111.3 30.3416 109.842 31.8411C108.404 33.3407 106.44 34.0904 103.949 34.0904C102.41 34.0904 101.033 33.7561 99.8178 33.0874C98.623 32.4186 97.6813 31.446 96.9928 30.1693C96.3043 28.8724 95.96 27.302 95.96 25.4579C95.96 22.702 96.6789 20.5844 98.1167 19.1052C99.5546 17.6259 101.529 16.8862 104.04 16.8862C105.6 16.8862 106.977 17.2206 108.171 17.8893C109.366 18.558 110.308 19.5307 110.996 20.8073C111.685 22.0637 112.029 23.6139 112.029 25.4579ZM100.577 25.4579C100.577 27.0993 100.841 28.3456 101.367 29.1967C101.914 30.0275 102.795 30.4429 104.01 30.4429C105.205 30.4429 106.065 30.0275 106.592 29.1967C107.139 28.3456 107.412 27.0993 107.412 25.4579C107.412 23.8165 107.139 22.5906 106.592 21.78C106.065 20.9492 105.195 20.5338 103.979 20.5338C102.785 20.5338 101.914 20.9492 101.367 21.78C100.841 22.5906 100.577 23.8165 100.577 25.4579Z"
                                        fill={`${this.state.color_text}`}/>
                                    <path
                                        d="M126.621 20.5946H121.184V33.7865H116.658V20.5946H111.22V17.1902H126.621V20.5946Z"
                                        fill={`${this.state.color_text}`}/>
                                    <path
                                        d="M133.904 16.8558C136.131 16.8558 137.832 17.3422 139.007 18.3149C140.202 19.2673 140.799 20.7364 140.799 22.7223V33.7865H137.64L136.759 31.5372H136.638C135.929 32.4288 135.18 33.0772 134.39 33.4825C133.6 33.8878 132.517 34.0904 131.139 34.0904C129.661 34.0904 128.436 33.6649 127.464 32.8138C126.492 31.9424 126.006 30.6151 126.006 28.8319C126.006 27.0689 126.623 25.772 127.859 24.9412C129.094 24.0901 130.947 23.624 133.418 23.543L136.304 23.4518V22.7223C136.304 21.8509 136.071 21.2126 135.605 20.8073C135.159 20.4021 134.532 20.1994 133.721 20.1994C132.911 20.1994 132.122 20.321 131.352 20.5642C130.583 20.7871 129.813 21.0708 129.043 21.4153L127.555 18.3452C128.446 17.8792 129.428 17.5144 130.502 17.251C131.595 16.9876 132.729 16.8558 133.904 16.8558ZM134.542 26.157C133.084 26.1976 132.071 26.461 131.504 26.9473C130.937 27.4337 130.653 28.072 130.653 28.8623C130.653 29.5513 130.856 30.0478 131.261 30.3517C131.666 30.6354 132.193 30.7773 132.841 30.7773C133.813 30.7773 134.633 30.4936 135.301 29.9262C135.969 29.3385 136.304 28.5178 136.304 27.4641V26.0963L134.542 26.157Z"
                                        fill={`${this.state.color_text}`}/>
                                    <path
                                        d="M166.422 17.1902L160.317 25.154L167 33.7865H162.17L155.851 25.3667V33.7865H151.629V25.3667L145.311 33.7865H140.481L147.164 25.154L141.058 17.1902H145.736L151.629 25.2452V17.1902H155.851V25.2452L161.744 17.1902H166.422Z"
                                        fill={`${this.state.color_text}`}/>
                                </svg>
                            </li>
                            <div>
                                <a className={'header_phone_block mb'} href={`tel:${phoneDataPriority.value}`}>
                                    <li className='header_li__item' onClick={this.clickHandler}
                                        style={{color: this.state.color_text}}>{phoneDataPriority.title}
                                    </li>
                                </a>
                                <a className={'header_phone_block'} href={`tel:${phoneData.value}`}>
                                    <li className='header_li__item' onClick={this.clickHandler}
                                        style={{color: this.state.color_text}}>{phoneData.title}
                                    </li>
                                </a>
                            </div>
                            <li className='header_li__item' style={{color: this.state.color_text}}>
                                ул. 2-я Станционная, 30к18
                            </li>
                            <li className='header_li__item'>
                                <a href="mailto:Kabotazh@inbox.ru" className='header_li__item' style={{color: this.state.color_text}}>
                                    Kabotazh@inbox.ru
                                </a>
                            </li>
                            <Media query="(max-width: 768px)" render={() =>
                                (
                                    <a href={`tel:${phoneDataPriority.value}`} className='btn_org_circle'>
                                        <p className='btn_header'>
                                        <img src={phone} alt=""/>&nbsp;
                                        </p>
                                    </a>
                                )}/>
                            <Media query="(min-width: 769px)" render={() =>
                                (
                                    <p style={{ cursor: 'pointer'}} onClick={this.props.toggleIsForm} className='btn_org_circle'>
                                        <p className='btn_header'>
                                            <img src={phone} alt=""/>&nbsp;
                                            <Media query="(min-width: 769px)" render={() =>
                                            (<span>Оставить заявку</span>)}/>
                                        </p>
                                    </p>
                                )}/>
                        </ul>
                    </nav>
                </div>
            </>
        );
    }
}

export default Header;
