import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import icon from '../../image/more_car.png'



const carTypes = {
    light: 0,
    truck: 1,
    bus: 2,
    spec: 3
};



class YandexMap extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            template: null
        };
    }


    render() {
        return(
            <div className="app">
                <YMaps
                    width={"100%"}
                >
                    <div className="app-map">
                        <Map

                            width={"100%"}
                            height={"50vh"}
                            defaultState={{
                                center: [55.008264,82.810265],
                                zoom: 18,
                                behaviors: ["multiTouch", "dblClickZoom", "rightMouseButtonMagnifier"],

                                controls: [],

                            }}

                        >
                            <Placemark

                            geometry={[55.008264,82.810265]}
                            iconContent= {""}
                            balloonContent= '<img src="http://img-fotki.yandex.ru/get/6114/82599242.2d6/0_88b97_ec425cf5_M" />'
                            />

                        </Map>

                    </div>
                </YMaps>
            </div>
        )
    }
}

export default  YandexMap
