import React from 'react';
import './AdvantagesBlock.scss'
import TitleBlock from "../TitleBlock/TitleBlock";
import img01 from '../../image/advantage01.png'
import img02 from '../../image/advantage02.png'
import img03 from '../../image/advantage03.png'
import img04 from '../../image/advantage04.png'
import person from '../../image/person.png'
import train from '../../image/train.png'
import guard from '../../image/guard.png'
import lines from '../../image/container_line.png'
import Media from "react-media";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';


// Import Swiper styles
import 'swiper/swiper.scss';


function AdvantagesBlock() {
    return (
        <>
            <div className="wrapper_advantages_block">
                <div className="wrapper_inner_container ">
                    <div className="advantages_block">
                        <TitleBlock title='Почему выбирают нас'
                                    sub_title='Мы занимаемся транспортно-логистическими услугами уже более 5 лет. Мы знаем как обезопасить ваш груз.'/>
                        <Media query="(min-width: 769px)" render={() =>
                            (
                                <div className="wrapper_advantages_items">

                                    <div className="advantages_item">
                                        <img className='advantages_item_back' src={img01} alt=""/>
                                        <div className="advantages_item_text">
                                            <img src={person} alt=""/>
                                            <p className='advantages_item_text_title'>Индвидуально</p>
                                            <p className='advantages_item_text_sub_title'>Персональный подход <br/> к
                                                каждому клиенту.</p>
                                        </div>
                                    </div>
                                    <div className="advantages_item">
                                        <img className='advantages_item_back' src={img02} alt=""/>
                                        <div className="advantages_item_text">
                                            <img src={train} alt=""/>
                                            <p className='advantages_item_text_title'>Полный комплекс</p>
                                            <p className='advantages_item_text_sub_title'>Полный
                                                комплекс <br/> транспортно-логистических услуг</p>
                                        </div>
                                    </div>
                                    <div className="advantages_item">
                                        <img className='advantages_item_back' src={img03} alt=""/>
                                        <div className="advantages_item_text">
                                            <img src={guard} alt=""/>
                                            <p className='advantages_item_text_title'>Надежность</p>
                                            <p className='advantages_item_text_sub_title'>Если что-то случится с вашим
                                                грузом, то мы несем 100% финансовой ответственности за сохранность
                                                груза</p>
                                        </div>
                                    </div>
                                    <div className="advantages_item">
                                        <img className='advantages_item_back' src={img04} alt=""/>
                                        <div className="advantages_item_text">
                                            <img src={lines} alt=""/>
                                            <p className='advantages_item_text_title'>Кросс-докинг</p>
                                            <p className='advantages_item_text_sub_title'>Приём и отгрузка грузов через склад напрямую, без размещения в зоне долговременного хранения</p>
                                        </div>
                                    </div>
                                </div>

                            )}/>
                        <Media query="(min-width: 481px)" and query="(max-width: 768px)" render={() =>
                            (
                                    < Swiper
                                        slidesPerView={2}
                                        navigation
                                        spaceBetween={0}
                                        breakpoints={
                                            {
                                                0: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 0
                                                },

                                                480: {
                                                    slidesPerView: 1,
                                                    spaceBetween:0
                                                }
                                            }
                                        }
                                    >

                                        < SwiperSlide >
                                            < div className="advantages_item">
                                                <img className='advantages_item_back' src={img01} alt=""/>
                                                <div className="advantages_item_text">
                                                    <img src={person} alt=""/>
                                                    <p className='advantages_item_text_title'>Индвидуально</p>
                                                    <p className='advantages_item_text_sub_title'>Персональный
                                                        подход <br/> к
                                                        каждому клиенту.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide >
                                            <div className="advantages_item">
                                                <img className='advantages_item_back' src={img02} alt=""/>
                                                <div className="advantages_item_text">
                                                    <img src={train} alt=""/>
                                                    <p className='advantages_item_text_title'>Полный комплекс</p>
                                                    <p className='advantages_item_text_sub_title'>Полный
                                                        комплекс <br/> транспортно-логистических услуг</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="advantages_item">
                                                <img className='advantages_item_back' src={img03} alt=""/>
                                                <div className="advantages_item_text">
                                                    <img src={guard} alt=""/>
                                                    <p className='advantages_item_text_title'>Надежность</p>
                                                    <p className='advantages_item_text_sub_title'>Если что-то случится с
                                                        вашим
                                                        грузом, то мы несем 100% финансовой ответственности за
                                                        сохранность
                                                        груза</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="advantages_item">
                                                <img className='advantages_item_back' src={img04} alt=""/>
                                                <div className="advantages_item_text">
                                                    <img src={lines} alt=""/>
                                                    <p className='advantages_item_text_title'>Кроссдокинг</p>
                                                    <p className='advantages_item_text_sub_title'>Если что-то случится с
                                                        вашим
                                                        грузом, то мы несем 100% финансовой ответственности за
                                                        сохранность
                                                        груза</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>


                            )}/>


                    </div>
                </div>
            </div>
        </>
    );
}

export default AdvantagesBlock;
